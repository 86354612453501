





















































































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import FieldDate from '@/components/FieldDate.vue';
import FieldRepeater from '@/components/FieldRepeater.vue';
import FieldPhoto from '@/components/FieldPhoto.vue';
import EXPENSE from '@/graphql/queries/Expense.graphql';
import EXPENSE_CREATE from '@/graphql/mutations/ExpenseCreate.graphql';
import EXPENSE_UPDATE from '@/graphql/mutations/ExpenseUpdate.graphql';
import { required, decimal } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import lightFormat from 'date-fns/lightFormat';
import { CODES, EXPENSE_STATUSES } from '@/utils/constants';
import {
  Expense,
  ExpenseCreateMutationVariables,
  ExpenseQueryVariables,
  ExpenseUpdateMutationVariables,
} from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'DriverExpensesAdd',
  components: {
    AppMain,
    AppForm,
    FieldDate,
    FieldRepeater,
    FieldPhoto,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        purpose: '',
        amount: '' as string | number,
        date: lightFormat(new Date(), 'yyyy-MM-dd'),
        comment: '',
        receipts: [] as { file: string }[],
      },
      expense: {} as Expense,
    };
  },
  computed: {
    purposeOptions() {
      return ['Parts', 'Repairs', 'Fuel', 'Other'];
    },
  },
  validations() {
    return {
      form: {
        purpose: { required },
        amount: { required, decimal },
        date: { required },
        comment: { required },
        receipts: {
          $each: {
            file: { required },
          },
        },
      },
    };
  },
  methods: {
    validationMessages,
    async saveExpense() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        if (this.$route.params.id) await this.updateExpense();
        else await this.addExpense();
        this.$router.replace(
          this.$route.meta.backTo || { name: 'driver-expenses' }
        );
        this.$notify({
          text: 'Expense saved!',
          type: 'success',
          duration: 6000,
        });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
    addExpense() {
      return this.$apollo.mutate({
        mutation: EXPENSE_CREATE,
        variables: {
          input: {
            purpose: this.form.purpose,
            date: this.form.date,
            comment: this.form.comment,
            amount: this.form.amount,
            receipts: this.form.receipts,
          },
        } as ExpenseCreateMutationVariables,
      });
    },
    updateExpense() {
      return this.$apollo.mutate({
        mutation: EXPENSE_UPDATE,
        variables: {
          input: {
            id: this.expense.id,
            purpose: this.form.purpose,
            date: this.form.date,
            comment: this.form.comment,
            amount: this.form.amount,
            receipts: this.form.receipts,
          },
        } as ExpenseUpdateMutationVariables,
      });
    },
    fillForm(expense: Expense) {
      if (!expense) return;
      this.form = {
        purpose: expense.purpose,
        amount: expense.amount,
        date: expense.date,
        comment: expense.comment,
        receipts: this.form.receipts,
      };
    },
  },
  apollo: {
    expense: {
      query: EXPENSE,
      variables(): ExpenseQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<{ expense: Expense }>) {
        if (!data) return;
        if (data.expense.status !== EXPENSE_STATUSES.PENDING) {
          this.$router.replace({
            name: 'driver-expenses-details',
            params: { id: data.expense.id },
          });
        }
        this.fillForm(data.expense);
      },
      error({ graphQLErrors }) {
        const [gqlError] = graphQLErrors;
        if (gqlError?.extensions?.code === CODES.NOT_FOUND) {
          this.$router.replace({ name: 'driver-expenses' });
        }
      },
      skip(): boolean {
        return !this.$route.params.id;
      },
      fetchPolicy: 'cache-and-network',
    },
  },
});
